<!-- eslint-disable max-len -->
<template>
    <div class="invention-terms apply-form-block">
        <div class="terms-box">
            <div class="terms-title">
                {{ $t('InventionApply.Terms.title') }}
            </div>
            <div class="terms-content">
                <p>
                    {{ $t('InventionApply.Terms.content[0]') }}
                </p>
                <p>
                    {{ $t('InventionApply.Terms.content[1]') }}
                </p>
                <p>
                    {{ $t('InventionApply.Terms.content[2]') }}
                </p>
                <p>
                    {{ $t('InventionApply.Terms.content[3]') }}
                </p>
                <p>
                    {{ $t('InventionApply.Terms.content[4]') }}
                </p>
                <p>
                    {{ $t('InventionApply.Terms.content[5]') }}
                </p>
                <p>
                    {{ $t('InventionApply.Terms.content[6]') }}
                </p>
                <p>
                    {{ $t('InventionApply.Terms.content[7]') }}
                </p>
                <p>
                    {{ $t('InventionApply.Terms.content[8]') }}
                </p>
                <p>
                    {{ $t('InventionApply.Terms.content[9]') }}
                </p>
                <p>
                    {{ $t('InventionApply.Terms.content[10]') }}
                </p>
            </div>
        </div>
        <div class="actions">
            <div
                class="btn white-btn"
                @click="prevStep"
            >
                {{ $t('InventionApply.Terms.button[0]') }}
            </div>
            <div
                class="btn orange-btn"
                @click="agree"
            >
                {{ $t('InventionApply.Terms.button[1]') }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {};
    },
    computed: {
        applyId() {
            return this.$route.params.id;
        },
    },
    created() { },
    mounted() { },
    methods: {
        prevStep() {
            this.$router.push({
                name: 'InventionProposal',
                params: {
                    id: this.applyId,
                },
            });
        },
        agree() {
            this.$router.push({
                name: 'InventionConfirm',
                params: {
                    id: this.applyId,
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.apply-form-block {
  flex-direction: column;
}
.invention-terms {
  .terms-box {
    width: 100%;
    border: 1px solid $color-orange;
    padding: 27px 24px;
    color: $color-gray;
    margin-bottom: 80px;
  }

  .terms-title {
    font-weight: 700;
    font-size: 21px;
    line-height: 1;
    letter-spacing: 0.1em;
    margin-bottom: 20px;
    text-align: center;
  }

  .terms-content {
    font-weight: 400;
    font-size: 16px;
    line-height: 2;
    text-align: justify;
    letter-spacing: 0.1em;
  }

  .actions {
    justify-content: center;

    .btn:first-child {
      margin-right: 30px;
    }
  }
}
</style>
